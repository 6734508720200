<template>
  <floating-dropdown
    :is-enabled="true"
    :custom-classes="{'bottom-right': true, 'dropdown-position-below': true}"
  >
    <template #trigger>
      <action-button
        :id="!!dropdownTriggerElementId ? dropdownTriggerElementId : false"
        :custom-classes="customClasses"
        message=""
        :is-disabled="isDisabled"
        data-cy="action-button-dropdown-trigger"
      >
        <chevron-down-icon />
      </action-button>
    </template>
    <template #content>
      <div
        v-for="(option, idx) in options"
        :key="idx"
        :data-cy="`dropdown-option-${idx}`"
        class="dropdown-compound-item"
        :class="{ 'dropdown-compound-item-disabled': option.disabled }"
        @click="!option.disabled ? onOptionSelected(option.key, idx) : () => {}"
      >
        {{ option.label }}
        <span
          v-if="option.key === defaultOptionId"
          class="default-marker"
        >default</span>
      </div>
      <slot name="extraContent" />
    </template>
  </floating-dropdown>
</template>
<script>
import { ChevronDownIcon } from '@zhuowenli/vue-feather-icons';
import ActionButton from '@/components/general/buttons/ActionButton.vue';
import FloatingDropdown from '@/components/forms/FloatingDropdown.vue';

export default {
  components: { ChevronDownIcon, FloatingDropdown, ActionButton },
  props: {
    customClasses: {
      type: Object,
      default: () => ({}),
    },
    options: {
      // Array of options e.g.: [ {key: 'OPTION_KEY', label: 'Option Display', disabled?: false}, ... ]
      type: Array,
      default: new Array(0),
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    dropdownTriggerElementId: {
      type: String,
      default: '',
    },
    defaultOptionId: {
      type: String,
      required: false,
      default: null,
    },
  },
  emits: ['optionSelected'],
  methods: {
    onOptionSelected(optionKey, optionIdx) {
      this.$log.info('onOptionSelected', optionKey, optionIdx);
      this.$emit('optionSelected', { optionKey, optionIdx });
    },
  },
};
</script>
