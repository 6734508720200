<template>
  <div class="action-button-split-group export-button">
    <action-button
      button-display="BTN_PRIMARY"
      message="Export"
      :custom-classes="{'btn-fixed-width-m': true, 'action-button-split-left': true }"
      :is-disabled="disableMetricExports"
      :style="{opacity: 1}"
      :is-loading="attemptingExport"
      :error-message="disableMetricExports ? 'Metrics can only be exported for verified documents' : null"
      @onClick="() => downloadExport(defaultMainExportOption.optionId, defaultMainExportOption.formatId)"
    />
    <action-button-options
      :custom-classes="{'action-button-split-right': true}"
      dropdown-trigger-element-id="exportOptionsDropdownTrigger"
    >
      <template #extraContent>
        <!-- Option menu -->
        <div
          v-for="option in mainExportOptions"
          :key="option.id"
        >
          <div
            @mouseover="isOptionDisabled(option) ? () => {} : showFormatSubmenu(option.id) "
          >
            <div
              class="dropdown-compound-item"
              :class="{'dropdown-compound-item-disabled': isOptionDisabled(option)}"
              @click="isOptionDisabled(option) || option.FORMAT_OPTIONS?.length ? () => { } : onExportOptionSelected(option.id)"
            >
              <span v-if="!isOptionDisabled(option) && option.FORMAT_OPTIONS?.length">
                <chevron-left-icon size="1.2x" />
              </span>
              <span v-if="option.isMetricExport && disableMetricExports">
                <alert-circle-icon
                  v-tooltip="'Metrics can only be exported for verified documents'"
                  stroke="red"
                />
              </span>

              <span
                v-if="defaultMainExportOption.optionId === option.id"
                class="default-marker"
              >default</span>

              <span class="export-option-label">{{ option.label }}</span>
              <!-- Option Format submenu -->
              <div
                v-if="visibleOptionFormatSubmenu === option.id"
                class="format-submenu"
                @mouseleave="visibleOptionFormatSubmenu = null"
              >
                <div
                  v-for="formatOption in option.FORMAT_OPTIONS"
                  :key="formatOption.id"
                  class="dropdown-compound-item"
                  @click="(e) => onExportFormatOptionSelected(e, option.id, formatOption.id)"
                >
                  <span>{{ formatOption.label }}</span>
                  <span
                    v-if="defaultMainExportOption.optionId === option.id && defaultMainExportOption.formatId === formatOption.id"
                    class="default-marker"
                  >default</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </action-button-options>

    <confirmation-modal
      :show="showWarning"
      :message="warning.warningMessage"
      modal-type="WARNING"
      title="Warning"
      @close="closeWarningModal"
      @confirm="onAcceptWarning"
    />
  </div>
</template>
<script>
import exportOptions from '@/store/helpers/mapping/exportOptions';
import ActionButtonOptions from '@/components/general/buttons/ActionButtonOptions.vue';
import ActionButton from '@/components/general/buttons/ActionButton.vue';
import { mapActions } from 'vuex';
import { errorMessages } from '@/store/helpers/display/toastMessages';
import { useToast } from 'vue-toastification';
import Api from '@/store/helpers/api';
import { ChevronLeftIcon, AlertCircleIcon } from '@zhuowenli/vue-feather-icons';
import ConfirmationModal from '../modals/ConfirmationModal.vue';

export default {
  name: 'Export',
  components: {
    ActionButton,
    ActionButtonOptions,
    ConfirmationModal,
    ChevronLeftIcon,
    AlertCircleIcon,
  },
  props: {
    documentRequests: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showWarning: false,
      warning: {
        warningMessage: '',
        optionId: null,
        format: null,
      },
      toast: useToast(),
      attemptingExport: false,
      defaultMainExportOption: exportOptions.DEFAULT_MAIN_EXPORT_OPTION,
      formatOptions: exportOptions.FORMAT_OPTIONS,
      mainExportOptions: exportOptions.MAIN_EXPORT_OPTIONS,
      warnings: exportOptions.WARNINGS,
      visibleOptionFormatSubmenu: null,
    };
  },
  computed: {
    disableMetricExports() {
      return (this.documentRequests.some((dr) => dr.isVerified === false));
    },
    documentRequestIds() {
      return this.documentRequests.map((req) => req.documentRequestId);
    },
  },
  methods: {
    ...mapActions({
      exportMany: 'documentRequest/exportMany',
      generateDocumentsReport: 'documentRequest/generateDocumentsReport',
    }),
    isOptionDisabled(option) {
      return (option.isMetricExport && this.disableMetricExports);
    },
    showFormatSubmenu(optionId) {
      this.visibleOptionFormatSubmenu = optionId;
    },
    triggerDropdown() {
      document.getElementById('exportOptionsDropdownTrigger').click(); // Close dropdown
    },
    onExportOptionSelected(optionId, format = null) {
      this.triggerDropdown();
      this.$log.debug(`Export triggered with with option: (${optionId}) - selected format: ${format}`);
      if (this.warnings[optionId]) {
        this.warning = {
          warningMessage: this.warnings[optionId],
          optionId,
          format,
        };
        this.showWarning = true;
      } else {
        this.downloadExport(optionId, format);
      }
    },
    onExportFormatOptionSelected(e, optionId, format) {
      e.stopPropagation();
      this.onExportOptionSelected(optionId, format);
      this.visibleOptionFormatSubmenu = null;
    },
    downloadExport(optionId, format = null) {
      switch (optionId) {
        case 'EXPORT_VERIFIED_METRICS':
          if (format === 'EXPORT_AS_EXCEL') {
            this.exportReport(this.documentRequestIds);
          } else {
            this.exportMetrics(this.documentRequestIds, format);
          }
          break;
        case 'EXPORT_ALL_METRICS':
          this.exportMetrics(this.documentRequestIds, format, true);
          break;
        case 'EXPORT_DOCUMENTS':
          this.exportDocuments(this.documentRequestIds);
          break;
        default:
          break;
      }
    },
    exportMetrics(documentRequestIds, format, includeUnverified = false) {
      this.exportMany({
        documentRequestIds,
        format,
        includeUnverified,
      }).then((res) => {
        this.$log.info('Successfully exported metrics: ', res);
      }).catch((e) => {
        this.toast.error(`${errorMessages.FAILED_EXPORT_METRICS}: ${e.message}`);
        this.$log.error('Export error', e);
      }).finally(() => { this.attemptingExport = false; });
    },
    async exportReport(documentRequestIds) {
      this.$log.debug('Generating report');
      this.attemptingExport = true;
      if (!this.documentRequestIds.length) {
        return Promise.resolve();
      }
      return this.generateDocumentsReport({ documentRequestIds })
        .then((r) => {
          this.$log.info('Generate report request successful, downloading report');
          const link = document.createElement('a');
          link.href = r.uri;
          link.click();
        })
        .catch((e) => {
          this.$log.error(`Generate report failed, error: ${e.message}`);
          this.toast.error(errorMessages.FAILED_GENERATE_REPORT);
        })
        .finally(() => { this.attemptingExport = false; });
    },
    async exportDocuments() {
      this.attemptingExport = true;
      if (!this.documentRequestIds.length) {
        return Promise.resolve();
      }
      const idToken = this.$store.getters['authenticate/idToken'];
      return (new Api(process.env, idToken))
        .post('document-requests/download-pdfs', { uuids: this.documentRequestIds })
        .then((r) => {
          this.$log.info('Generate zip of documents successful, downloading zip');
          const link = document.createElement('a');
          link.href = r.downloadUrl;
          link.click();
        }).catch((e) => {
          this.$log.error(`Generate zip failed, error: ${e.message}`);
          this.toast.error(errorMessages.FAILED_EXPORT_DOCUMENTS);
        })
        .finally(() => { this.attemptingExport = false; });
    },
    closeWarningModal() {
      // Reset warning details
      this.warning = {
        warningMessage: '',
        optionId: null,
        format: null,
      };
      // Close modal
      this.showWarning = false;
    },
    onAcceptWarning() {
      const { optionId, format } = this.warning;
      this.downloadExport(optionId, format);
      this.closeWarningModal();
    },
  },
};

</script>
<style lang="scss">
.export-button {
  .dropdown-content {
    margin-top: 3px;
    min-width: 270px;
    right: 0 !important;
  }

  .dropdown-compound-item {
    display: flex;
    position: relative;
  }

  .export-option-label {
    font-size: 16px !important;
    margin: 0;
    width: 100%;
    text-align: right;
  }

  .default-marker {
    white-space: nowrap;
  }

  .format-submenu {
    background-color: $bg-body;
    border-radius: 5px;
    border: 1px solid #F2F2F2;
    bottom: 0;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    height: fit-content;
    min-width: 180px;
    padding: 0 !important;
    position: absolute;
    right: 100%;
    top: 0;
    width: fit-content;
    z-index: 1000;
  }
}
</style>
