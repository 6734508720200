<template>
  <table class="contained-table">
    <tr :style="{zIndex: '1', position: 'relative'}">
      <th class="table-col-0">
        <checkbox-input
          id="entitySelectAll"
          :model-value="isAllRowsSelected"
          @update:modelValue="onToggleSelectAll"
        />
      </th>
      <th>
        <span>Name</span>
        <span
          class="icon-container column-header-icon"
          :class="sortedColumnClass('name')"
          @click="onClickColumnSort('name')"
        >
          <triangle-icon
            class="icon-sort"
            size="0.75x"
          />
        </span>
      </th>
      <th class="text-centre">
        <span>AKA</span>
        <span
          class="icon-container column-header-icon"
          :class="sortedColumnClass('akaCount')"
          @click="onClickColumnSort('akaCount')"
        >
          <triangle-icon
            class="icon-sort"
            size="0.75x"
          />
        </span>
      </th>
      <th>
        <span>Type</span>
        <span
          class="icon-container column-header-icon"
          :class="sortedColumnClass('metricIdentifierDisplayName')"
          @click="onClickColumnSort('metricIdentifierDisplayName')"
        >
          <triangle-icon
            class="icon-sort"
            size="0.75x"
          />
        </span>
      </th>
      <th class="text-centre">
        <span>Last Edit</span>
        <span
          class="icon-container column-header-icon"
          :class="sortedColumnClass('timeUpdated')"
          @click="onClickColumnSort('timeUpdated')"
        >
          <triangle-icon
            class="icon-sort"
            size="0.75x"
          />
        </span>
      </th>
    </tr>
    <tr
      v-for="relatedEntity in relatedEntities"
      :key="relatedEntity.interDocumentEntityId"
    >
      <td class="table-col-0">
        <checkbox-input
          :id="`entitySelect-${relatedEntity.interDocumentEntityId}`"
          :model-value="relatedEntity.relationships.some((r) => rowSelectionData[r.interDocumentRelationshipId]?.isSelected)"
          @update:modelValue="onToggleSelectRow(relatedEntity.relationships.map((relationship) => relationship.interDocumentRelationshipId))"
        />
      </td>
      <td class="name-column">
        <router-link
          :to="{ name: 'entity-overview', params: { name: relatedEntity.name }}"
          class="cell-link"
        >
          {{ relatedEntity.name }}
        </router-link>
      </td>
      <td class="text-centre aka-count-column">
        {{ relatedEntity.akaCount }}
      </td>
      <td>
        <div
          v-if="relatedEntity.relationships.length > 0"
          class="type-pills"
        >
          <entity-type-pill
            v-for="(displayName, idx) in entityRelationshipTypes(relatedEntity)"
            :key="idx"
            :display-name="displayName"
          />
        </div>
        <span
          v-else
          class="missing-info-placeholder"
        >
          Not defined
        </span>
      </td>
      <td class="text-centre last-edit-column">
        <span v-if="relatedEntity.timeUpdated">{{ formatDate(relatedEntity.timeUpdated) }}</span>
        <span
          v-else
          class="missing-info-placeholder"
        >-</span>
      </td>
    </tr>
  </table>
  <p
    v-if="!relatedEntities.length"
    class="hint"
  >
    No entity relationships to display.
  </p>
</template>
<script>
import dayjs from 'dayjs';
import EntityTypePill from '@/components/entities/EntityTypePill/EntityTypePill.vue';
import { TriangleIcon } from '@zhuowenli/vue-feather-icons';
import { mapGetters } from 'vuex';
import uniq from 'lodash.uniq';
import CheckboxInput from '../../forms/CheckboxInput.vue';

export default {
  components: {
    EntityTypePill,
    CheckboxInput,
    TriangleIcon,
  },
  props: {
    relatedEntities: {
      type: Array,
      required: true,
    },
    rowSelectionData: {
      type: Object,
      required: true,
    },
    isAllRowsSelected: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['toggleSelectRows', 'toggleSelectAll'],
  computed: {
    ...mapGetters({
      orderBy: 'entityRelationships/orderBy',
    }),
    orderByColumn() {
      return this.orderBy !== null ? this.orderBy.split(':')[0] : null;
    },
    orderByDirection() {
      return this.orderBy !== null ? this.orderBy.split(':')[1] : null;
    },
    sortIconClass() {
      if (this.orderByDirection === 'desc') { return 'sort-desc'; }
      if (this.orderByDirection === 'asc') { return 'sort-asc'; }
      return '';
    },
  },
  methods: {
    onClickColumnSort(columnName) {
      // Remove sort if this column is already sorted in descending direction
      if (this.orderByColumn === columnName && this.orderByDirection === 'desc') {
        this.updateOrderByQueryParam(null);
        return;
      }
      // Sort this column by descending if this column already sorted in ascending direction
      if (this.orderByColumn === columnName && this.orderByDirection === 'asc') {
        this.updateOrderByQueryParam(`${columnName}:desc`);
        return;
      }
      // Sort by ascending
      this.updateOrderByQueryParam(`${columnName}:asc`);
    },
    sortedColumnClass(columnName) {
      if (this.orderByColumn === columnName) {
        return this.sortIconClass;
      }
      return '';
    },
    updateOrderByQueryParam(orderBy) {
      this.$router.push({ query: { ...this.$route.query, orderBy } });
    },
    entityRelationshipTypes(entity) {
      // Return an entity's relationships types/display names without duplicates
      return uniq(entity.relationships.map((r) => r.displayName));
    },
    formatDate(datetime) {
      if (dayjs(datetime).isValid()) {
        return dayjs(datetime).format('MMM DD YYYY');
      }
      return '';
    },
    onToggleSelectAll() {
      this.$emit('toggleSelectAll');
    },
    onToggleSelectRow(relationshipsIds) {
      this.$emit('toggleSelectRows', relationshipsIds);
    },
  },
};
</script>
<style lang="scss" scoped>
.type-pills {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.contained-table {
  font-size: $txt-p-size;
  margin-bottom: 55px;
  min-width: 900px;

  .name-column  {
    width: 40%;
    min-width: 360px;
  }

  .type-column, .aka-count-column, .last-edit-column {
    width: 20%;
    min-width: 180px;
  }

  td {
    vertical-align: middle;
  }

  th {
    white-space: nowrap;
    &.table-col-0 {
      z-index: 1;
      width: 58px;
    }

    .icon-container:hover {
      background-color: transparent;
    }
  }
}

.icon-sort {
  fill: $bg-body;
  stroke: $bg-button-grey;
  transform: rotate(180deg);
  transform-origin: 50% 50%;
}

.sort-desc {
  svg {
    fill: $bg-body-neg;
    stroke: $bg-body-neg;
  }
}

.sort-asc {
  svg {
    transform: rotate(0deg);
    fill: $bg-body-neg;
    stroke: $bg-body-neg;
  }
}
</style>
