<template>
  <div class="radio-form-group-options">
    <h3 class="radio-form-group-title">
      {{ title }}
    </h3>
    <div
      v-for="option in options"
      :key="option.id"
      class="radio-form-group-option"
    >
      <input
        :id="option.id"
        v-model="selectedOptionId"
        type="radio"
        :value="option.id"
      >
      <label :for="option.id">{{ option.label }}</label>
    </div>
  </div>
</template>
<script>
export default {
  name: 'RadioFormGroup',
  props: {
    title: {
      type: String,
      required: false,
      default: 'Options',
    },
    options: {
      type: Array, // Format: {id: string, label: string}[]
      required: true,
    },
    defaultOptionId: {
      type: String,
      required: false,
      default: null,
    },
  },
  emits: ['setSelectedOptionId'],
  data() {
    return {
      selectedOptionId: this.defaultOptionId,
    };
  },
  watch: {
    selectedOptionId(newId) {
      this.$emit('setSelectedOptionId', newId);
    },
  },

};
</script>
<style lang="scss" scoped>
.radio-form-group-options {
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 8px;

  .radio-form-group-option {
    display: flex;
    align-items: center;
    label {
      font-size: $txt-p-size;
      font-weight: $semibold;
      color: black;
      align-self: center;
    }
    input {
      margin-left: 0;
      margin-top: 2px;
      cursor: pointer;
    }
  }
  .radio-form-group-title {
    font-size: $txt-p-size;
    color: $txt-col-grey;
    margin: 0;
    line-height: unset;
    cursor: default;
  }
}
</style>
