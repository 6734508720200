import { DocumentRequestTag } from '@/store/documents/types';

export const documentTagsOverridedProperties: { [key: string]: Partial<DocumentRequestTag> } = {
  'PENDING APPROVAL': {
    textColor: '#FF9D00',
    backgroundColor: '#FFF5E5',
  },
  'MULTIPLE DOCUMENTS': {
    textColor: '#3B82F6',
    backgroundColor: '#EBF3FE',
  },
};

export const DEFAULT_TEXT_COLOR = '#426D70';
export const DEFAULT_BACKGROUND_COLOR = '#D9E2E2';

export const tagTextColor = (label: string) => documentTagsOverridedProperties[label]?.textColor;

export const tagBackgroundColor = (
  label: string,
) => documentTagsOverridedProperties[label]?.backgroundColor;
