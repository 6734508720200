<template>
  <modal
    :show="show"
    :title="title"
    :custom-classes="['modal-bg-side-nav', 'confirmation-modal']"
    @close="close"
  >
    <template #extra-header-content>
      <alert-triangle-icon
        v-if="modalType === 'WARNING'"
        class="warning-icon"
        size="2x"
      />
    </template>
    <template
      v-if="true"
      class="modal-body"
    >
      <p data-testid="modal-message">
        {{ message }}
        <slot name="customBodyContent" />
      </p>
    </template>
    <template #footer>
      <div class="modal-footer has-2-col">
        <action-button
          button-display="BTN_NEG"
          message="CANCEL"
          :custom-classes="{'top-nav-item': true}"
          :style="{'width': '170px'}"
          data-testid="close-modal-button"
          @onClick="close"
        />
        <action-button
          button-display="BTN_PRIMARY_FILLED"
          :style="{ 'font-size': '18px', 'padding': '2px', 'width': '170px' } /** To match style with cancel button */"
          :message="confirmButtonLabel"
          :custom-classes="{'top-nav-item': true }"
          data-testid="continue-button"
          @onClick="confirm"
        />
      </div>
    </template>
  </modal>
</template>
<script>
import { AlertTriangleIcon } from '@zhuowenli/vue-feather-icons';
import Modal from './Modal.vue';
import ActionButton from '../general/buttons/ActionButton.vue';

export default {
  name: 'ConfirmationModal',
  components: {
    ActionButton,
    AlertTriangleIcon,
    Modal,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    message: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    modalType: {
      type: String,
      required: false,
      default: '',
    },
    confirmButtonLabel: {
      type: String,
      required: false,
      default: 'CONTINUE',
    },
  },
  emits: ['close', 'confirm'],
  methods: {
    close() {
      this.$emit('close');
    },
    confirm() {
      this.$emit('confirm');
    },
  },
};
</script>
