import FEATURE_FLAGS from '../helpers/featureFlags';

const ALL_DOCUMENT_TABLE_HEADERS = [
  'documentName',
  'documentType',
  'dueDate',
  'paymentDueDate',
  'fund',
  'fundManager',
  'gpName',
  'companyName',
  'supplierName',
  'transactionId',
  'orderId',
  'limitedPartner',
  'placementAgent',
  'bankName',
  'accountNumber',
  'uploadedDate',
  'uploadedBy',
  'tags',
  ...FEATURE_FLAGS.ENABLE_NEW_USER_ACCEPTANCE_WORK_FLOW ? ['userAcceptanceStateUpdatedBy'] : ['verifiedBy'],
];

export default { ALL_DOCUMENT_TABLE_HEADERS };
