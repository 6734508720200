import FEATURE_FLAGS from '../helpers/featureFlags';
import { DocumentTableHeader, DocumentTableHeaders } from './types';

/**
 * --- DEVELOPMENT WARNING ---
 *
 * This approach is temporary, in the long term we aim to configure the
 * document columns from our backend.
 *
 * After you update the 'tableHeaders', you also need to update:
 *   - 'backendToDocument' to map the backend date to the table headers
 *   - 'selectedDocumentRequests' computed method, to make sure the
 *     right verified column is selected (you might have to append the
 *     index)
 *
 * Note: Stick to 8 visible columns to avoid the table being too large
 *       to fit on a screen.
 */

const statusModifiedByColumn: DocumentTableHeader = FEATURE_FLAGS
  .ENABLE_NEW_USER_ACCEPTANCE_WORK_FLOW
  ? { key: 'userAcceptanceStateUpdatedBy', display: 'Status Modified By' }
  : { key: 'verifiedBy', display: 'Verified By' };

// Treat all fields under the following columns as datapoints
const DATAPOINT_COLUMN_IDS = [
  'dueDate',
  'fund',
  'fundManager',
  'paymentDueDates',
  'fund',
  'fundManager',
  'paymentDueDate',
  'gpName',
  'companyName',
  'supplierName',
  'transactionId',
  'orderId',
  'limitedPartner',
  'placementAgent',
  'bankName',
  'accountNumber',
];
export const isDatapointField = (columnId: string) => DATAPOINT_COLUMN_IDS.includes(columnId);

// Treat values of all fields under the following columns as dates
const DATE_COLUMN_IDS = ['paymentDueDate', 'uploadedDate', 'dueDate'];
export const isDateField = (columnId: string) => DATE_COLUMN_IDS.includes(columnId);

const tableHeaders = (): DocumentTableHeaders => {
  const DEFAULT_HEADERS: DocumentTableHeader[] = [
    { key: 'documentName', display: 'Document Name' },
    { key: 'documentType', display: 'Document Type' },
    { key: 'dueDate', display: 'Due Date' },
    { key: 'paymentDueDate', display: 'Due Date', hidden: true },
    { key: 'fund', display: 'Fund', hidden: true },
    { key: 'fundManager', display: 'Fund Manager', hidden: true },
    { key: 'gpName', display: 'Fund Manager', hidden: true },
    { key: 'companyName', display: 'Company Name', hidden: true },
    { key: 'supplierName', display: 'Supplier', hidden: true },
    { key: 'transactionId', display: 'Transaction', hidden: true },
    { key: 'orderId', display: 'Order', hidden: true },
    { key: 'limitedPartner', display: 'LP', hidden: true },
    { key: 'placementAgent', display: 'Agent', hidden: true },
    { key: 'bankName', display: 'Custodian', hidden: true },
    { key: 'accountNumber', display: 'Account number', hidden: true },
    { key: 'uploadedDate', display: 'Upload Date' },
    { key: 'uploadedBy', display: 'Uploaded By' },
    { key: 'tags', display: 'Status' },
    statusModifiedByColumn,
  ];

  switch (process.env.VUE_APP_CLIENT) {
    case 'ps':
      return {
        DEFAULT: [
          { key: 'documentName', display: 'Document Name' },
          { key: 'documentType', display: 'Document Type' },
          { key: 'transactionId', display: 'Investment ID' },
          { key: 'orderId', display: 'Order ID' },
          { key: 'fund', display: 'Fund' },
          { key: 'limitedPartner', display: 'LP' },
          { key: 'dueDate', display: 'Reporting Date' },
          { key: 'paymentDueDate', display: 'Due Date', hidden: true },
          { key: 'fundManager', display: 'Fund Manager', hidden: true },
          { key: 'gpName', display: 'Fund Manager', hidden: true },
          { key: 'companyName', display: 'Company Name', hidden: true },
          { key: 'supplierName', display: 'Supplier', hidden: true },
          { key: 'placementAgent', display: 'Agent', hidden: true },
          { key: 'bankName', display: 'Custodian', hidden: true },
          { key: 'accountNumber', display: 'Account number', hidden: true },
          { key: 'uploadedDate', display: 'Upload Date' },
          { key: 'uploadedBy', display: 'Uploaded By' },
          { key: 'tags', display: 'Status' },
          statusModifiedByColumn,
        ],
      };
    default:
      return {
        DEFAULT: DEFAULT_HEADERS,
        am_capital_notice: [
          { key: 'documentName', display: 'Document Name' },
          { key: 'documentType', display: 'Document Type' },
          { key: 'dueDate', display: 'Due Date' },
          { key: 'paymentDueDate', display: 'Due Date', hidden: true },
          { key: 'fund', display: 'Fund' },
          { key: 'fundManager', display: 'Fund Manager' },
          { key: 'gpName', display: 'Fund Manager', hidden: true },
          { key: 'companyName', display: 'Company Name', hidden: true },
          { key: 'supplierName', display: 'Supplier', hidden: true },
          { key: 'transactionId', display: 'Transaction', hidden: true },
          { key: 'orderId', display: 'Order', hidden: true },
          { key: 'limitedPartner', display: 'LP', hidden: true },
          { key: 'placementAgent', display: 'Agent', hidden: true },
          { key: 'bankName', display: 'Custodian', hidden: true },
          { key: 'accountNumber', display: 'Account number', hidden: true },
          { key: 'uploadedDate', display: 'Upload Date' },
          { key: 'uploadedBy', display: 'Uploaded By' },
          { key: 'tags', display: 'Status' },
          statusModifiedByColumn,
        ],
        am_performance: [
          { key: 'documentName', display: 'Document Name' },
          { key: 'documentType', display: 'Document Type' },
          { key: 'dueDate', display: 'Reporting Date' },
          { key: 'paymentDueDate', display: 'Due Date', hidden: true },
          { key: 'fund', display: 'Fund' },
          { key: 'fundManager', display: 'Fund Manager' },
          { key: 'gpName', display: 'Fund Manager', hidden: true },
          { key: 'companyName', display: 'Company Name', hidden: true },
          { key: 'supplierName', display: 'Supplier', hidden: true },
          { key: 'transactionId', display: 'Transaction', hidden: true },
          { key: 'orderId', display: 'Order', hidden: true },
          { key: 'limitedPartner', display: 'LP', hidden: true },
          { key: 'placementAgent', display: 'Agent', hidden: true },
          { key: 'bankName', display: 'Custodian', hidden: true },
          { key: 'accountNumber', display: 'Account number', hidden: true },
          { key: 'uploadedDate', display: 'Upload Date' },
          { key: 'uploadedBy', display: 'Uploaded By' },
          { key: 'tags', display: 'Status' },
          statusModifiedByColumn,
        ],
        am_company_performance: [
          { key: 'documentName', display: 'Document Name' },
          { key: 'documentType', display: 'Document Type' },
          { key: 'dueDate', display: 'Reporting Date' },
          { key: 'paymentDueDate', display: 'Due Date', hidden: true },
          { key: 'fund', display: 'Fund', hidden: true },
          { key: 'fundManager', display: 'Fund Manager', hidden: true },
          { key: 'gpName', display: 'Fund Manager', hidden: true },
          { key: 'companyName', display: 'Company Name' },
          { key: 'supplierName', display: 'Supplier', hidden: true },
          { key: 'transactionId', display: 'Transaction', hidden: true },
          { key: 'orderId', display: 'Order', hidden: true },
          { key: 'limitedPartner', display: 'LP', hidden: true },
          { key: 'placementAgent', display: 'Agent', hidden: true },
          { key: 'bankName', display: 'Custodian', hidden: true },
          { key: 'accountNumber', display: 'Account number', hidden: true },
          { key: 'uploadedDate', display: 'Upload Date' },
          { key: 'uploadedBy', display: 'Uploaded By' },
          { key: 'tags', display: 'Status' },
          statusModifiedByColumn,
        ],
        am_contribution_notice: [
          { key: 'documentName', display: 'Document Name' },
          { key: 'documentType', display: 'Document Type' },
          { key: 'dueDate', display: 'Due Date', hidden: true },
          { key: 'paymentDueDate', display: 'Due Date' },
          { key: 'fund', display: 'Fund' },
          { key: 'fundManager', display: 'Fund Manager', hidden: true },
          { key: 'gpName', display: 'Fund Manager' },
          { key: 'companyName', display: 'Company Name', hidden: true },
          { key: 'supplierName', display: 'Supplier', hidden: true },
          { key: 'transactionId', display: 'Transaction', hidden: true },
          { key: 'orderId', display: 'Order', hidden: true },
          { key: 'limitedPartner', display: 'LP', hidden: true },
          { key: 'placementAgent', display: 'Agent', hidden: true },
          { key: 'bankName', display: 'Custodian', hidden: true },
          { key: 'accountNumber', display: 'Account number', hidden: true },
          { key: 'uploadedDate', display: 'Upload Date' },
          { key: 'uploadedBy', display: 'Uploaded By' },
          { key: 'tags', display: 'Status' },
          statusModifiedByColumn,
        ],
        am_distribution_notice: [
          { key: 'documentName', display: 'Document Name' },
          { key: 'documentType', display: 'Document Type' },
          { key: 'dueDate', display: 'Issue Date' },
          { key: 'paymentDueDate', display: 'Due Date', hidden: true },
          { key: 'fund', display: 'Fund' },
          { key: 'fundManager', display: 'Fund Manager', hidden: true },
          { key: 'gpName', display: 'Fund Manager' },
          { key: 'companyName', display: 'Company Name', hidden: true },
          { key: 'supplierName', display: 'Supplier', hidden: true },
          { key: 'transactionId', display: 'Transaction', hidden: true },
          { key: 'orderId', display: 'Order', hidden: true },
          { key: 'limitedPartner', display: 'LP', hidden: true },
          { key: 'placementAgent', display: 'Agent', hidden: true },
          { key: 'bankName', display: 'Custodian', hidden: true },
          { key: 'accountNumber', display: 'Account number', hidden: true },
          { key: 'uploadedDate', display: 'Upload Date' },
          { key: 'uploadedBy', display: 'Uploaded By' },
          { key: 'tags', display: 'Status' },
          statusModifiedByColumn,
        ],
        am_invoice: [
          { key: 'documentName', display: 'Document Name' },
          { key: 'documentType', display: 'Document Type' },
          { key: 'dueDate', display: 'Issued' },
          { key: 'paymentDueDate', display: 'Due Date', hidden: true },
          { key: 'fund', display: 'Fund' },
          { key: 'fundManager', display: 'Fund Manager', hidden: true },
          { key: 'gpName', display: 'Fund Manager', hidden: true },
          { key: 'companyName', display: 'Company Name', hidden: true },
          { key: 'supplierName', display: 'Supplier' },
          { key: 'transactionId', display: 'Transaction', hidden: true },
          { key: 'orderId', display: 'Order', hidden: true },
          { key: 'limitedPartner', display: 'LP', hidden: true },
          { key: 'placementAgent', display: 'Agent', hidden: true },
          { key: 'bankName', display: 'Custodian', hidden: true },
          { key: 'accountNumber', display: 'Account number', hidden: true },
          { key: 'uploadedDate', display: 'Upload Date' },
          { key: 'uploadedBy', display: 'Uploaded By' },
          { key: 'tags', display: 'Status' },
          statusModifiedByColumn,
        ],
        am_reference_custodian_bank_statement: [
          { key: 'documentName', display: 'Document Name' },
          { key: 'documentType', display: 'Document Type' },
          { key: 'dueDate', display: 'Performance Date' },
          { key: 'paymentDueDate', display: 'Due Date', hidden: true },
          { key: 'fund', display: 'Fund', hidden: true },
          { key: 'fundManager', display: 'Fund Manager', hidden: true },
          { key: 'gpName', display: 'Fund Manager', hidden: true },
          { key: 'companyName', display: 'Company Name', hidden: true },
          { key: 'supplierName', display: 'Supplier', hidden: true },
          { key: 'transactionId', display: 'Transaction', hidden: true },
          { key: 'orderId', display: 'Order', hidden: true },
          { key: 'limitedPartner', display: 'LP', hidden: true },
          { key: 'placementAgent', display: 'Agent', hidden: true },
          { key: 'bankName', display: 'Custodian' },
          { key: 'accountNumber', display: 'Account number' },
          { key: 'uploadedDate', display: 'Upload Date' },
          { key: 'uploadedBy', display: 'Uploaded By' },
          { key: 'tags', display: 'Status' },
          statusModifiedByColumn,
        ],
      };
  }
};

export const tableHeadersByDocumentType = (documentType: string) => {
  const headers = tableHeaders();
  if (Object.keys(headers).includes(documentType)) {
    return headers[documentType as keyof DocumentTableHeaders];
  }
  return headers.DEFAULT;
};
